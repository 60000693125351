<template>
    <div class="file-upload-wrapper">
        <Button
            buttonSize="small"
            iconClass="upload-btn"
            buttonWord="選擇檔案"
            buttonStyle="blue-hollow"
            @click="clickUploadInput"
        />
        <input
            type="file"
            :name="name"
            :required="required"
            :accept="accept"
            :multiple="multiple"
            :capture="capture"
            @change="selectedFile"
            ref="fileInput"
            hidden
        />
        <div class="file-name">
            <p
                :class="{ 'has-file': file || imageUrl }"
                :title="fileName"
                :tooltip="file ? fileName : ''"
                tooltip-touch
            >
                {{ fileName }}
            </p>
            <XButton
                v-if="file || imageUrl"
                class="x-button"
                @click.prevent="deleteHandler"
            />
        </div>
        <span v-if="uploadError">*{{ uploadError }}</span>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import XButton from '@/components/XButton.vue'
export default {
    name: 'FileUploader',
    components: { Button, XButton },
    emits: ['update:files', 'update:imageUrl'],
    props: {
        modelValue: {},
        imageUrl: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        capture: {
            type: String,
            default: ''
        },
        isImage: {
            type: Boolean,
            default: false
        },
        sizeLimit: {
            type: Object,
            default: function () {
                return {
                    unit: 'MB',
                    size: 50
                }
            }
        }
    },
    methods: {
        selectedFile: function (event) {
            const files = event.target.files || event.dataTransfer.files || null
            if (!files[0]) return
            this.file = files[0]
            this.fileName = this.file.name
            this.$emit('update:files', this.file)

            if (this.isImage) {
                // this.imageUrl = window.URL.createObjectURL(this.file)
                this.$emit(
                    'update:imageUrl',
                    window.URL.createObjectURL(this.file)
                )
            }
            const unit = this.sizeLimit.unit
            const size = this.sizeLimit.size
            switch (unit) {
                case 'MB':
                    this.uploadError =
                        size * 1024 * 1024 > this.file.size
                            ? ''
                            : `超過${size + unit}限制大小`
                    break
                case 'KB':
                    this.uploadError =
                        size * 1024 > this.file.size
                            ? ''
                            : `超過${size + unit}限制大小`
                    break
                default:
                    this.uploadError = ''
                    break
            }
            // const isValidType =
            //     this.fileName.substring(this.fileName.lastIndexOf('.')) ===
            //     '.xlsx'
            // if (!isValidType) this.uploadError = '檔案類型錯誤'
            // else this.uploadError = ''
        },
        clickUploadInput: function () {
            this.$refs.fileInput.click()
            this.$setGaEvent(`selectedFile_${this.name}`, 'click-Button')
        },
        deleteHandler: function () {
            this.file = null
            this.fileName = '未選擇任何檔案'
            this.$emit('update:files', this.file)
            this.$emit('update:imageUrl', '')
        }
    },
    watch: {
        imageUrl: {
            handler(val) {
                if (!this.file && val) {
                    this.fileName = '預設圖檔'
                } else if (!this.file && !val) {
                    this.fileName = '未選擇任何檔案'
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            file: null,
            uploadError: '',
            fileName: '未選擇任何檔案'
        }
    }
}
</script>

<style lang="scss" scoped>
.file-name {
    display: flex;
    align-items: center;
    // flex-direction: column;
    p {
        margin: 0;
        font-size: 14px;
        color: $fourth-black-disabled;
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.has-file {
            max-width: 90px;
            color: $sixth-black;
            &:hover {
                // &::after {
                // }
            }
        }
    }
    :deep(.x-cancel-icon) {
        width: 12px;
        height: 12px;
        margin-left: 5px;
    }
}
span {
    color: $primary-red;
    font-size: 12px;
}

.file-upload-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

:deep(button) {
    margin-right: 5px;
}
</style>
