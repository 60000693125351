<template>
    <button class="x-cancel-icon"></button>
</template>

<script>
export default {
    name: 'XButton'
}
</script>

<style lang="scss" scoped>
.x-cancel-icon {
    height: 16px;
    width: 16px;
    float: right;
    border: none;
}
</style>
